import React, { useState } from "react";
import { Link } from "react-router-dom";
import Header from '../layout/header12';
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import { getCertificateByRefNo, getUserById } from "../server";

import Footer from "../layout/footer4";

import Swal from "sweetalert2";
import moment from "moment";

const bg = require("../../images/background/bg24.png");


function SearchCertificate() {



    const [certificateNo, setCertificateNo] = useState("");
    const [searchTerm, setSearchTerm] = useState("");
    const [pdfUrl, setPdfUrl] = useState("");
    const [docData, setDocData] = useState();
    const id = localStorage.getItem("userId");

    useEffect(() => {
        getUserById({ id })
            .then((res) => {
                console.log("rescert", res?.data);

                setCertificateNo(res?.data?.certificateNo);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const handleSearch = () => {
        if (searchTerm) {

            getCertificateByRefNo(searchTerm)
                .then((res) => {
                    console.log("resAboutUs", res?.data?.document?.url);

                    // setTotalPages(Math.ceil(res.data.length / numOfElements));
                    if (res?.data?.document?.url) {
                        setPdfUrl("https://rest.alcumusbd.org/abc/" + res?.data?.document?.url);
                        setDocData(res?.data)
                    }
                    else {
                        setPdfUrl("")
                        Swal.fire({
                            title: 'No Match!',
                            text: 'No document found with this certificate number',
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                        });
                    }
                })
                .catch((err) => {
                    console.log(err);
                });

        } else {

            setPdfUrl("");
        }
    };





    useEffect(() => {
        if (certificateNo) {
            console.log("certificateNo", certificateNo);
        }
    }, [certificateNo]);

    return (
        <>
            <Header />
            <div className="page-content bg-white">
                <div
                    className="dlab-bnr-inr overlay-black-middle bg-pt"
                    style={{ backgroundImage: "url(" + bg + ")" }}
                >
                    <div className="container">
                        <div className="dlab-bnr-inr-entry">
                            <div
                                className="col"
                                style={{
                                    textAlign: "left",
                                }}
                            >
                                <h1 className="text-white">Certificate</h1>
                                {certificateNo && (
                                    <h6
                                        className="text-white"
                                        style={{
                                            textAlign: "left",
                                            marginTop: "10px",
                                            marginLeft: "10px",
                                        }}
                                    >
                                        Certificate No : {certificateNo}
                                    </h6>
                                )}
                            </div>
                            <div className="breadcrumb-row">
                                <ul className="list-inline">
                                    <Link to="/">
                                        <li>Home{">"} </li>
                                    </Link>
                                    <Link
                                        onClick={() => {
                                            localStorage.removeItem("userId");
                                            localStorage.removeItem("access_token");
                                            window.location.href = "/";
                                        }}
                                    >
                                        <li>Certificate</li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="certificate-search-container">
                    <header className="certificate-header">
                        <h1>Search Certificate</h1>
                        <p>Enter the certificate ID to search for document.</p>
                    </header>

                    <div className="search-bar">
                        <input
                            type="text"
                            placeholder="Enter certificate ID"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <button onClick={handleSearch}>Search</button>
                    </div>

                    {pdfUrl && (
                        <div className="pdf-container">
                            <h2>Certificate Found</h2>
                            <table className="certificate-info-table">
                                <thead>
                                    <tr>
                                        <th>Company Name</th>
                                        <th>Document Name</th>
                                        <th>Remark</th>
                                        <th>Expiry Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{docData?.user?.name}</td>
                                        <td>{docData?.documentName}</td>
                                        <td>{docData?.remarks || "N/A"}</td>
                                        <td>{moment(docData?.expireDate).format("DD-MM-YYYY")}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <iframe
                                src={`${pdfUrl}#zoom=0`}
                                title="Certificate PDF"
                                className="pdf-viewer"
                            ></iframe>
                            <div className="download-button">
                                <a href={pdfUrl} target="_blank" download>
                                    Download PDF
                                </a>
                            </div>
                        </div>
                    )}
                </div>


            </div>
            <Footer />
        </>
    );
}
export default SearchCertificate;
